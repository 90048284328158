export const actions = {
  set_state: 'scan_qr/set_state',
  get_scan_qr: 'scan_qr/get_scan_qr',
  get_scan_qr_unique: 'scan_qr/get_scan_qr_unique',
  get_scan_qr_product: 'scan_qr/get_scan_qr_product',
  check_unique: 'scan_qr/check_unique',
  get_scan_qr_sku: 'scan_qr/get_scan_qr_sku',
  get_batch: 'scan_qr/get_batch',
  move_batch: 'scan_qr/move_batch'
}
