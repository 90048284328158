/* eslint-disable react/react-in-jsx-scope */
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { message, notification } from 'antd'
import { actions } from 'src/redux/scan-qr/actions'
import { serviceScanQR } from 'src/services/api/scanQR'
import { serviceCoupon } from 'src/services/api/coupons'

function load (payload) {
  return setState({ loading: payload })
}

function setState (payload) {
  return put({
    type: 'scan_qr/set_state',
    payload
  })
}

function setCouponState (payload) {
  return put({
    type: 'coupon/set_state',
    payload
  })
}

export function * getScanQr ({ payload }) {
  yield load(true)
  const { qrid, body } = payload
  // console.log('data', body)
  const response = yield call(serviceScanQR.get, qrid, body)

  // const { qrid, uniqueId, latitude, longititude } = payload
  // // console.log(payload)
  // const response = yield call(serviceScanQR.get, qrid, uniqueId, latitude, longititude)
  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/get_scan_qr',
      data: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false, data: response.data })
}

export function * getScanQrProduct ({ payload }) {
  yield load(true)
  const { qrid, body } = payload

  const response = yield call(serviceScanQR.getProduct, qrid, body)

  // const { qrid, uniqueId, latitude, longititude } = payload
  // // console.log(payload)
  // const response = yield call(serviceScanQR.get, qrid, uniqueId, latitude, longititude)
  if (body.coupon) {
    const checkCoupon = yield call(serviceCoupon.check, {
      data: {
        qrcodeId: qrid,
        coupon: body?.coupon
      }
    })

    if (checkCoupon.status !== 200) {
      yield setCouponState({
        dataCoupon: null
      })

      message.error(checkCoupon?.data.message)
      return
    }
  }

  if (response.status === 200) {
    if (body.uniqueCode) {
      openNotification('success', 'Success', 'Coupon has been claimed', 'top')
    }

    if (body.coupon) {
      if (response.data.data.limitCheck && response.data.data.totalChecked !== response.data.data.limitCheck) {
        notification.warning({
          message: 'Try again',
          description: 'Scan lagi untuk klaim kupon discount'
        })

        return yield setState({
          type: 'scan_qr/get_scan_qr_product',
          data: response.data.data,
          loading: false
        })
      }

      let number = response.data.data?.product?.phone
      const first = number[0]
      const productName = response.data.data?.product?.name.replaceAll(' ', '%20')
      if (first === '0') {
        number = number.replace(first, '62')
      }

      const desc = (
        <div>
          Silahkan hubungi nomor <a href={`https://api.whatsapp.com/send?phone=${number}&text=Halo!%20%0ASaya%20ingin%20mengkonfirmasi%20diskon%20karena%20telah%20berhasil%20menukarkan%20kupon%20pada%20produk%20${productName} untuk penggunaan kuponnya.`} target='_blank' rel='noreferrer'>+{number}</a> untuk mendapatkan diskon untuk pembelian berikutnya
        </div>
      )

      notification.success({
        message: 'Berhasil Claim Coupon!',
        description: desc,
        duration: 0
      })
    }

    // kondisi sku khusus (jangan sampai terhapus)
    const skuQR = response.data?.data?.sku
    const firstPart = skuQR.split('-')[0]
    const lastPart = skuQR.split('-')[2]
    const middlePart = parseInt(skuQR.split('-')[1], 10)

    // jika darusyifa
    if (firstPart === 'Dsi001' && lastPart === '00001' && middlePart >= '30301' && middlePart <= '106200') {
      response.data.data.displayMode = 'simple'
    } else if (firstPart === 'MHP' && lastPart === '1kg' && middlePart >= '0001' && middlePart <= '10500') { // jika alyaman
      response.data.data.displayMode = 'full'
    }

    yield setState({
      type: 'scan_qr/get_scan_qr_product',
      data: response.data.data,
      loading: false
    })
  } else {
    // notification.warning({
    //   message: 'Try again',
    //   description: 'Scan lagi untuk klaim kupon discount'
    // })

    openNotification('error', response.data.error, response.data.message, 'top')
    yield setState({ loading: false, message: response.data.message, statusCode: response.data.statusCode })
  }
}

export function * getScanQrSku ({ payload }) {
  yield load(true)
  const { sku } = payload
  // console.log('data', body)
  const response = yield call(serviceScanQR.getBySku, sku)

  // const { qrid, uniqueId, latitude, longititude } = payload
  // // console.log(payload)
  // const response = yield call(serviceScanQR.get, qrid, uniqueId, latitude, longititude)
  if (response.status === 202) {
    yield setState({
      type: 'scan_qr/get_scan_qr_sku',
      data: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false, data: response.data })
}

export function * getScanQrUnique ({ payload }) {
  yield load(true)
  const { qrid, uniqueId } = payload

  const response = yield call(serviceScanQR.get, qrid, uniqueId)
  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/get_scan_qr_unique',
      dataUnique: response.data.data,
      loading: false
    })
  } else yield setState({ loading: false, data: response.data })
}

export function * checkUnique ({ payload }) {
  yield load(true)
  const { body } = payload
  // console.log('data', body)
  const response = yield call(serviceScanQR.checkId, body)

  // const { qrid, uniqueId, latitude, longititude } = payload
  // // console.log(payload)
  // const response = yield call(serviceScanQR.get, qrid, uniqueId, latitude, longititude)
  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/check_unique',
      dataUnique: response.data.data,
      loading: false
    })
    message.success('Berhasil memasukan Unique ID')
  } else {
    yield setState({ loading: false })
    message.error('Unique ID yang anda masukan tidak sesuai, coba lagi..')
  }
}

export function * getBatch ({ payload }) {
  yield load(true)

  const { qrcodeId } = payload

  const response = yield call(serviceScanQR.getBatch, qrcodeId)

  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/get_batch',
      dataBatch: response.data.data,
      loading: false
    })
  } else {
    yield setState({ loading: false })

    message.error(response.data.message)
  }
}

export function * moveBatch ({ payload }) {
  yield load(true)

  const { body } = payload

  const response = yield call(serviceScanQR.moveBatch, body)

  if (response.status === 200) {
    yield setState({
      type: 'scan_qr/move_batch',
      dataBatch: response.data.data,
      loading: false
    })

    message.success('Batch successfully moved')
  } else {
    yield setState({ loading: false })

    message.error(response.data.message)
  }
}

const openNotification = (type, message, description, placement) => {
  notification[type]({
    message,
    description,
    placement
  })
}

export default function * rootSaga () {
  yield all([
    takeEvery(actions.get_scan_qr, getScanQr),
    takeEvery(actions.get_scan_qr_unique, getScanQrUnique),
    takeEvery(actions.get_scan_qr_product, getScanQrProduct),
    takeEvery(actions.get_scan_qr_sku, getScanQrSku),
    takeEvery(actions.check_unique, checkUnique),
    takeEvery(actions.get_batch, getBatch),
    takeEvery(actions.move_batch, moveBatch)
  ])
}
