/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Descriptions, Form, Image, Input, Layout, Modal, Row, Space, Spin, Tabs, notification } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'

import LogoSNI from 'src/assets/images/logo-sni.png'
import LogoMUI from 'src/assets/images/logo-mui.png'
import LogoHalal from 'src/assets/images/logo-halal.png'
import LogoUI from 'src/assets/images/logo-ui.png'
import LogoBPOM from 'src/assets/images/logo-bpom.png'
import LogoOriginal from 'src/assets/images/logo-original.png'
import CertificateEmpty from 'src/assets/images/certificate-empty.png'

import bgLeft from 'src/assets/images/Left.svg'
import bgRight from 'src/assets/images/Right.svg'
import bgBottom from 'src/assets/images/Down.svg'

import { Gap } from 'src/components/atoms/Gap'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Text from 'antd/lib/typography/Text'

import iconHerbal from 'src/assets/images/icon-herbal.png'
import iconFood from 'src/assets/images/icon-food.svg'
import iconFashion from 'src/assets/images/icon-fashion.png'

import iconFacebook from 'src/assets/images/socials/facebook-round.png'
import iconTwitter from 'src/assets/images/socials/twitter-round.png'
import iconTiktok from 'src/assets/images/socials/tiktok-round.png'
import iconInstagram from 'src/assets/images/socials/instagram-round.png'
import iconYoutube from 'src/assets/images/socials/youtube-round.png'
import iconGlobe from 'src/assets/images/socials/globe-round.png'

import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { rules } from 'src/utils/rules'
import Slider from 'react-slick'
import { LocationOff } from './LocationOff'

export const _Redirect = (props) => {
  const { dispatch, data, loading, dataCoupon, dataUnique, dataBatch, productPixel, gtmProduct } = props
  const { pathname, search } = useLocation()
  const [locationStatus, setLocationStatus] = useState('unset')
  const [location, setLocation] = useState({
    latitude: null,
    longititude: null
  })
  const [visibleCoupon, setVisibleCoupon] = useState(false)
  const [activeCoupon, setActiveCoupon] = useState(null)
  const [alert, setAlert] = useState(false)
  const [visibleUniqueId, setVisibleUniqueId] = useState(false)
  const [form] = Form.useForm()
  const query = new URLSearchParams(search)

  const statusError = [400, 404]

  const productionDate = pathname === '/product' && data && !statusError.includes(data.statusCode) && data?.productionDate ? data?.productionDate?.split('T') : ''
  const expiredDate = pathname === '/product' && data && !statusError.includes(data.statusCode) && data?.expiredAt !== null ? data?.expiredAt?.split('T') : '-'
  const category = pathname === '/product' && data && !statusError.includes(data.statusCode) ? data?.product?.category : ''
  let iconTop

  switch (category) {
    case 'herbal':
      iconTop = iconHerbal
      break
    case 'food':
      iconTop = iconFood
      break
    case 'fashion':
      iconTop = iconFashion
      break
    default:
      iconTop = iconHerbal
  }

  const settings = {
    infinite: false,
    autoplay: false,
    arrows: false,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    slidesToShow: 1.5,
    slidesToScroll: 1
  }

  useEffect(() => {
    if (pathname === '/qr') {
      // onHandleAllowLocation()
      const sku = query.get('sku')
      // const body = {
      //   latitude: '',
      //   longititude: ''
      // }
      dispatch({
        type: 'scan_qr/get_scan_qr_sku',
        payload: {
          sku
        }
      })
    }

    if (pathname === '/product') {
      const qrid = query.get('qrcodeId')
      const trackId = query.get('trackId')

      const body = {
        trackId
      }
      dispatch({
        type: 'product_pixel/get_product_pixel_qr',
        payload: {
          qrcodeId: qrid
        }
      })
      dispatch({
        type: 'gtm_product/get_gtm_product_qr',
        payload: {
          qrcodeId: qrid
        }
      })
      dispatch({
        type: 'scan_qr/get_scan_qr_product',
        payload: {
          qrid,
          body
        }
      })

      dispatch({
        type: 'scan_qr/get_batch',
        payload: {
          qrcodeId: qrid
        }
      })
    }
  }, [pathname])

  useEffect(() => {
    if (!loading && data && data?.statusCode !== 400 && pathname !== '/product') {
      window.location.replace(data?.urlScapPage)
    }
  }, [loading, data, pathname])

  useEffect(() => {
    if (productPixel) {
      const index = productPixel?.events?.findIndex(f => f.position === 'landing-page-scan')
      ReactPixel.init(productPixel.pixelId)
      ReactPixel.pageView()
      ReactPixel.track(productPixel?.events[index].eventName, {})
    }
  }, [productPixel])

  useEffect(() => {
    if (gtmProduct) {
      TagManager.initialize({
        gtmId: gtmProduct.googleTagManager
      })
    }
  }, [gtmProduct])

  useEffect(() => {
    if (!dataCoupon) {
      form.setFieldsValue({
        couponCode: null
      })
      setActiveCoupon(null)
      return
    }

    setActiveCoupon(dataCoupon)
  }, [dataCoupon])

  const onHandleFinishUniqueId = () => {
    const qrid = query.get('qrcodeId')
    form.validateFields().then(data => {
      const body = {
        qrcodeId: qrid,
        uniqueId: data?.uniqueId
      }
      dispatch({
        type: 'scan_qr/check_unique',
        payload: { qrid, body }
      })
    })
    setVisibleUniqueId(false)
  }

  const onHandleFinishCoupon = async () => {
    const index = productPixel?.events?.findIndex(f => f.position === 'submit')
    const qrid = query.get('qrcodeId')
    const trackId = query.get('trackId')
    // if (dataUnique?.uniqueCode) {
    const valueForm = form.getFieldsValue()
    form.validateFields().then((data) => {
      const body = {
        trackId,
        latitude: String(location.latitude),
        longititude: String(location.longititude),
        uniqueCode: dataUnique?.uniqueCode,
        coupon: data?.couponCode || valueForm?.couponCode,
        name: data?.name || valueForm?.name,
        phone: data?.phone || valueForm?.phone,
        email: data?.email || valueForm?.email,
        eventName: productPixel?.events[index].eventName || 'Purchase'
      }
      dispatch({
        type: 'scan_qr/get_scan_qr_product',
        payload: { qrid, body }
      })
    })
    setVisibleCoupon(false)
    ReactPixel.track(productPixel?.events[index].eventName, {
      currency: 'IDR',
      value: data.product.price
    })
    form.resetFields()
  }

  const checkCoupon = () => {
    const qrid = query.get('qrcodeId')
    form.validateFields().then((data) => {
      dispatch({
        type: 'coupon/check_coupon',
        payload: {
          data: {
            qrcodeId: qrid,
            coupon: data?.couponCode
          }
        }
      })
    })
  }

  const claimCoupon = () => {
    // console.log('a')
    // onHandleAllowLocation()
    const index = productPixel?.events?.findIndex(f => f.position === 'button-popup-coupon')
    ReactPixel.track(productPixel?.events[index].eventName, {})
    navigator.geolocation.getCurrentPosition((position) => {
      setLocationStatus('on')
      setLocation({
        latitude: position.coords.latitude,
        longititude: position.coords.longitude
      })
      setVisibleCoupon(true)
      // setLatitude(position.coords.latitude)
      // setLongitude(position.coords.longitude)
    }, (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        setLocationStatus('off')
        setAlert(true)
      }
    })
  }

  const handleChangeBatch = (e) => {
    const qrid = query.get('qrcodeId')

    const body = {
      qrcodeId: qrid,
      newBatch: Number(e.currentTarget.value)
    }

    if (body.prevBatch === body.newBatch) {
      notification.warn({
        message: 'Warning',
        description: 'You are already in this batch'
      })
      return
    }

    dispatch({
      type: 'scan_qr/move_batch',
      payload: { body }
    })
  }

  const onHandleAllowLocation = () => {
    const sku = query.get('sku')
    navigator.geolocation.getCurrentPosition((position) => {
      setLocationStatus('on')
      const body = {
        latitude: position.coords.latitude,
        longititude: position.coords.longitude
      }
      dispatch({
        type: 'scan_qr/get_scan_qr_sku',
        payload: {
          sku
        }
      })
      // setLatitude(position.coords.latitude)
      // setLongitude(position.coords.longitude)
    }, (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        setLocationStatus('off')
      }
    })
  }

  const fashionAttributes = (attr) => {
    switch (attr) {
      case 'model':
        return 'Model'
      case 'color':
        return 'Warna'
      case 'size':
        return 'Ukuran'
      case 'material':
        return 'Bahan'
      default:
        return null
    }
  }

  // console.log(data?.product?.name)

  const socialMediaIcon = (sosmedName) => {
    switch (sosmedName) {
      case 'facebook':
        return iconFacebook
      case 'twitter':
        return iconTwitter
      case 'instagram':
        return iconInstagram
      case 'youtube':
        return iconYoutube
      case 'tiktok':
        return iconTiktok
      default:
        return iconGlobe
    }
  }

  return (
    <>
      {pathname === '/qr' &&
        <Layout
          className='scan-page-container webview-component' style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
            // backgroundColor: locationStatus === 'off' ? '#fff' : '#f0f2f5'
          }}
        >
          {(loading && !data?.isQrcodeExpired)
            ? (
              <>
                <Spin spinning size='large' className='mb-4' />
                Redirecting please wait...
              </>
              )
            // : !loading && locationStatus === 'off'
            //     ? <LocationOff />
            : !loading && data?.statusCode === 400
                ? 'Qrcode Has Reached Maximum Check'
                : null}
          {/* {!loading && data?.isQrcodeExpired && } */}
        </Layout>}
      {pathname === '/product' &&
        <Layout className='scan-page-container webview-component'>
          {data && !statusError.includes(data.statusCode)
            ? (
              <>
                <Card className='pb-lg-5 pb-0'>
                  <img src={bgLeft} className='bg-left' />
                  <img src={bgRight} className='bg-right' />
                  <img src={bgBottom} className='bg-bottom' />
                  <Image src={iconTop} width={48} style={{ opacity: 0.8 }} />

                  <p className='big-title-sertifikat'>
                    <span>CERTIFICATE</span>
                    <br />
                    {(!data.expiredAt && !data.limitCheck) ? null : 'Original Product'}
                  </p>
                  <div className='logo-wrapper'>
                    <Image src={data.product.images[0] ? data.product.images[0].url : iconTop} preview={false} width={150} style={{ borderRadius: 20 }} />
                    {/* {(!data.expiredAt && !data.limitCheck) ? null : <img src={LogoOriginal} className='bg-original' />} */}
                    {!dataUnique ? null : <img src={LogoOriginal} className='bg-original' />}
                    {(!data.expiredAt && !data.limitCheck) ? null : <img src={LogoOriginal} className='bg-original' />}
                  </div>
                  <Gap height='.6rem' />
                  <Layout.Content className='certificate-description px-0 px-md-3 px-lg-3'>
                    {data?.displayMode === 'full' &&
                      <>
                        <Row justify='center' className='description-items' style={{ padding: 0 }}>
                          <Descriptions.Item>
                            <div className='d-flex justify-content-between w-50 text-left'>Nama Produk
                              <Text>:</Text>
                            </div>
                          </Descriptions.Item>
                          <span className='text-right w-50 pl-1'>{data.product.name}</span>
                        </Row>
                      </>}
                    <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                      <Descriptions.Item>
                        <div className='d-flex justify-content-between w-50 text-left'>Produk asli dari..
                          <Text>:</Text>
                        </div>
                      </Descriptions.Item>
                      <span className='text-right w-50 pl-1'>{data.product.producedBy}</span>
                    </Row>
                    <Gap height='.6rem' />
                    {data?.displayMode === 'full' &&
                      <>
                        <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                          <Descriptions.Item>
                            <div className='d-flex justify-content-between w-50 text-left'>Diproduksi oleh
                              <Text>:</Text>
                            </div>
                          </Descriptions.Item>
                          <span className='text-right w-50 pl-1'>{data.product.producedBy}</span>
                        </Row>
                        <Gap height='.6rem' />
                        <Row justify='center' className='description-items' style={{ padding: 0 }}>
                          <Descriptions.Item>
                            <div className='d-flex justify-content-between w-50 text-left'>Distributor
                              <Text>:</Text>
                            </div>
                          </Descriptions.Item>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {
                            data?.distros?.map((item, index) => {
                              return <span key={index} className='text-right w-100 pl-1'>{item.name}</span>
                            })
                          }
                          </div>
                          <span className='text-right w-50 pl-1'>{data.product.distributedBy}</span>
                        </Row>
                        <Gap height='.6rem' />
                        <Row justify='center' className='description-items' style={{ padding: 0 }}>
                          <Descriptions.Item>
                            <div className='d-flex justify-content-between w-50 text-left'>Diterbitkan oleh
                              <Text>:</Text>
                            </div>
                          </Descriptions.Item>
                          <span className='text-right w-50 pl-1'>{data.product.producedBy}</span>
                        </Row>
                        <Gap height='.6rem' />
                        <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                          <Descriptions.Item>
                            <div className='d-flex justify-content-between w-50 text-left'>Tanggal Terbit
                              <Text>:</Text>
                            </div>
                          </Descriptions.Item>
                          <span className='text-right w-50 pl-1'>{productionDate[0] ? productionDate[0] : productionDate}</span>
                        </Row>
                        <Gap height='.6rem' />
                        <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                          <Descriptions.Item>
                            <div className='d-flex justify-content-between w-50 text-left'>Expired Date
                              <Text>:</Text>
                            </div>
                          </Descriptions.Item>
                          <span className='text-right w-50 pl-1'>{expiredDate[0] ? expiredDate[0] : expiredDate}</span>
                        </Row>
                        <Gap height='.6rem' />
                        {
                          data.product.licenses.map((item, index) => {
                            return (
                              <div key={index}>
                                <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                                  <Descriptions.Item>
                                    <div className='d-flex justify-content-between w-50 text-left'>No. {item.type}
                                      <Text>:</Text>
                                    </div>
                                  </Descriptions.Item>
                                  <span className='text-right w-50 pl-1'>{item.no}</span>
                                </Row>
                                <Gap height='.6rem' />
                              </div>
                            )
                          })
                        }
                        <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                          <Descriptions.Item>
                            <div className='d-flex justify-content-between w-50 text-left'>Deskripsi
                              <Text>:</Text>
                            </div>
                          </Descriptions.Item>
                          <span className='text-right w-50 pl-1'>{data.product.shortDescription}</span>
                        </Row>
                        <Gap height='.6rem' />
                        {
                          data.product.category === 'fashion'
                            ? (
                                data.product.attributes.map((item, index) =>
                                  <Row justify='center' className='description-items hover' style={{ padding: 0, marginBottom: '.6rem' }} key={index}>
                                    <Descriptions.Item>
                                      <div className='d-flex justify-content-between w-50 text-left'>
                                        {
                                          fashionAttributes(item.key)
                                        }
                                        <Text>:</Text>
                                      </div>
                                    </Descriptions.Item>
                                    <span className='text-right w-50 pl-1'>{item.value}</span>
                                  </Row>
                                )
                              )
                            : null
                        }
                        <Gap height='.6rem' />
                        {data.batchSize > 1 &&
                          <>
                            <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                              <Descriptions.Item>
                                <div className='d-flex justify-content-between w-50 text-left'>Batch
                                  <Text>:</Text>
                                </div>
                              </Descriptions.Item>
                              <span className='text-right w-50 pl-1'>{data.batch}</span>
                            </Row>
                            <Gap height='.6rem' />
                          </>}
                      </>}
                    {/* <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                      <Descriptions.Item>
                        <div className='d-flex justify-content-between w-50 text-left'>Diproduksi oleh
                          <Text>:</Text>
                        </div>
                      </Descriptions.Item>
                      <span className='text-right w-50 pl-1'>{data.product.producedBy}</span>
                    </Row>
                    <Gap height='.6rem' />
                    <Row justify='center' className='description-items' style={{ padding: 0 }}>
                      <Descriptions.Item>
                        <div className='d-flex justify-content-between w-50 text-left'>Distributor
                          <Text>:</Text>
                        </div>
                      </Descriptions.Item>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                        data?.distros?.map((item, index) => {
                          return <span key={index} className='text-right w-100 pl-1'>{item.name}</span>
                        })
                      }
                      </div> */}
                    {/* <span className='text-right w-50 pl-1'>{data.product.distributedBy}</span> */}
                    {/* </Row>
                    <Gap height='.6rem' />
                    <Row justify='center' className='description-items' style={{ padding: 0 }}>
                      <Descriptions.Item>
                        <div className='d-flex justify-content-between w-50 text-left'>Diterbitkan oleh
                          <Text>:</Text>
                        </div>
                      </Descriptions.Item>
                      <span className='text-right w-50 pl-1'>{data.product.producedBy}</span>
                    </Row>
                    <Gap height='.6rem' />
                    <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                      <Descriptions.Item>
                        <div className='d-flex justify-content-between w-50 text-left'>Tanggal Terbit
                          <Text>:</Text>
                        </div>
                      </Descriptions.Item>
                      <span className='text-right w-50 pl-1'>{productionDate[0] ? productionDate[0] : productionDate}</span>
                    </Row>
                    <Gap height='.6rem' />
                    <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                      <Descriptions.Item>
                        <div className='d-flex justify-content-between w-50 text-left'>Expired Date
                          <Text>:</Text>
                        </div>
                      </Descriptions.Item>
                      <span className='text-right w-50 pl-1'>{expiredDate[0] ? expiredDate[0] : expiredDate}</span>
                    </Row>
                    <Gap height='.6rem' /> */}
                    {/* {
                    data.product.licenses.map((item, index) => {
                      return (
                        <div key={index}>
                          <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                            <Descriptions.Item>
                              <div className='d-flex justify-content-between w-50 text-left'>No. {item.type}
                                <Text>:</Text>
                              </div>
                            </Descriptions.Item>
                            <span className='text-right w-50 pl-1'>{item.no}</span>
                          </Row>
                          <Gap height='.6rem' />
                        </div>
                      )
                    })
                  } */}
                    {/* <Row justify='center' className='description-items hover' style={{ padding: 0 }}>
                      <Descriptions.Item>
                        <div className='d-flex justify-content-between w-50 text-left'>Diskripsi
                          <Text>:</Text>
                        </div>
                      </Descriptions.Item>
                      <span className='text-right w-50 pl-1'>{data.product.shortDescription}</span>
                    </Row>
                    <Gap height='.6rem' />
                    {
                    data.product.category === 'fashion'
                      ? (
                          data.product.attributes.map((item, index) =>
                            <Row justify='center' className='description-items hover' style={{ padding: 0, marginBottom: '.6rem' }} key={index}>
                              <Descriptions.Item>
                                <div className='d-flex justify-content-between w-50 text-left'>
                                  {
                                    fashionAttributes(item.key)
                                  }
                                  <Text>:</Text>
                                </div>
                              </Descriptions.Item>
                              <span className='text-right w-50 pl-1'>{item.value}</span>
                            </Row>
                          )
                        )
                      : null
                  }
                    <Gap height='.6rem' /> */}
                  </Layout.Content>
                  <Gap height='1rem' />
                  {data.product.socials &&
                    <>
                      <Gap height='1rem' />
                      <Row justify='center' align='middle'>
                        <Col span={24} className='socials'>
                          {data.product.socials.map((social, index) => {
                            return (
                              <a key={index} target='_blank' href={social?.url} rel='noreferrer'>
                                <img src={socialMediaIcon(social?.name.toLowerCase())} alt={'icn-' + social?.name.toLowerCase()} />
                              </a>
                            )
                          })}

                        </Col>
                      </Row>
                    </>}
                  {data.product.certificates.length > 0 &&
                    <>
                      <Gap height='1rem' />
                      <Row justify='center'>
                        <Col span={24} className='carousel'>
                          <h5>Sertifikat</h5>
                          <Slider {...settings}>
                            {data.product.certificates.map(c =>
                              <div className='carousel-item' key={c?.id}>
                                <div className='carousel-image-wrapp'>
                                  <Image className='carousel-image' src={c?.url ?? CertificateEmpty} alt={`cert-${c?.id}`} />
                                </div>
                                <div className='carousel-text'>
                                  {c?.data}
                                </div>
                              </div>

                            )}
                          </Slider>
                        </Col>
                      </Row>
                    </>}
                  <Gap height='1rem' />
                  <Row justify='center'>
                    <Layout.Content className='footer-description'>
                      <span>
                        Sertifikat keaslian produk telah dijamin keasliannya oleh OPIM.
                        Segala tindakan pemalsuan akan dipidana sesuai hukum yang berlaku.
                        <br />
                        <Gap height='1rem' />
                        @2021 Ori Premium Indonesia Market
                      </span>
                    </Layout.Content>
                  </Row>
                  <Row justify='center' gutter={16} className='pb-lg-5 pb-0'>
                    <Space size='large'>
                      {
                      data.product.licenses.map((item, index) => {
                        const data = item.type === 'halal-mui'
                          ? <Image src={LogoMUI} preview={false} width={50} key={index} />
                          : item.type === 'sni'
                            ? <Image src={LogoSNI} preview={false} width={40} key={index} />
                            : item.type === 'ui'
                              ? <Image src={LogoUI} preview={false} width={50} key={index} />
                              : item.type === 'halal'
                                ? <Image src={LogoHalal} preview={false} width={50} key={index} />
                                : item.type === 'bpom'
                                  ? <Image src={LogoBPOM} preview={false} width={50} key={index} />
                                  : null
                        return data
                      })
                    }
                    </Space>
                  </Row>
                </Card>

                <Modal
                  // style={{ top: 20 }}
                  title='⚠️ Penting!'
                  visible={alert}
                  onCancel={() => setAlert(!alert)}
                  footer={false}
                  bodyStyle={{ textAlign: 'center' }}
                  closable
                >
                  Aktifkan izin lokasi terlebih dahulu dibrowser anda untuk dapat melakukan claim coupon
                </Modal>
                <Modal
                  // style={{ top: 20 }}
                  visible={visibleUniqueId}
                  onOk={() => setVisibleUniqueId(!visibleUniqueId)}
                  onCancel={() => setVisibleUniqueId(!visibleUniqueId)}
                  footer={false}
                  bodyStyle={{ textAlign: 'center' }}
                  closable
                >
                  <Gap height='2rem' />
                  <Form form={form} className='d-flex flex-row align-items-center flex-wrap' onFinish={onHandleFinishUniqueId}>
                    <Form.Item className='w-75 mr-2' name='uniqueId'>
                      <Input placeholder='Type your unique code' size='large' />
                    </Form.Item>
                    <Form.Item>
                      <Button type='primary' size='medium' htmlType='submit'>Check</Button>
                    </Form.Item>
                  </Form>
                </Modal>
                <Modal
                  title='Claim Coupon'
                  visible={visibleCoupon}
                  onOk={() => setVisibleCoupon(!visibleCoupon)}
                  onCancel={() => setVisibleCoupon(!visibleCoupon)}
                  footer={false}
                  bodyStyle={{ textAlign: 'center' }}
                  closable
                  className='modal-claim'
                >
                  {/* <Gap height='2rem' /> */}
                  <Form form={form} layout='vertical' autoComplete='off' className='d-flex flex-row align-items-center flex-wrap' onFinish={onHandleFinishCoupon}>
                    <Form.Item label='Nama' className='w-100 mr-2' name='name'>
                      <Input placeholder='Name' size='large' />
                    </Form.Item>
                    <Form.Item label='No. Telp' className='w-100 mr-2' name='phone'>
                      <Input placeholder='Phone' size='large' />
                    </Form.Item>
                    <Form.Item label='Email' className='w-100 mr-2' name='email'>
                      <Input placeholder='Email' size='large' type='email' />
                    </Form.Item>
                    <div className='coupon-form' style={{ flexDirection: 'column' }}>
                      <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                        <Form.Item label='Code Coupon' className='mr-2 customColumn' style={{ width: '80%' }} name='couponCode'>
                          <Input placeholder='Code Coupon' size='large' />
                        </Form.Item>
                        <Form.Item className='mr-2' style={{ marginTop: 'auto' }}>
                          <Button type='primary' size='medium' loading={loading} onClick={() => checkCoupon()} style={{ backgroundColor: '#4500BA', borderColor: '#4500BA' }}>Check</Button>
                        </Form.Item>
                      </div>
                      {dataCoupon &&
                        <div className='ant-alert-info' style={{ marginBottom: 24, textAlign: 'left', backgroundColor: '#27AE6033', borderColor: '#27AE60', borderRadius: 10 }}>
                          <Col span={24} className='py-2 px-4'>
                            <div className='mb-1' style={{ fontWeight: 'bold' }}>{dataCoupon?.coupon}</div>
                            <div style={{ fontSize: 13 }}>
                              {`Dapatkan Diskon ${dataCoupon?.discountCalculated?.percentage}% `}
                              {/* <span
                                className='mx-1' style={{
                                  textDecoration: 'line-through',
                                  color: '#4500BA'
                                }}
                              >{`Rp ${Intl.NumberFormat('id').format(dataCoupon?.product?.price)}`}
                              </span> {`Rp${Intl.NumberFormat('id').format(dataCoupon?.product?.price - dataCoupon?.discountCalculated?.priceDiscounted)} untuk pembelian selanjutnya pada produk yang sama`} */}
                              untuk pembelian selanjutnya pada produk yang sama
                            </div>
                          </Col>
                        </div>}
                    </div>
                    <Form.Item style={{ width: '100%', marginTop: '12px' }}>
                      <Button type='primary' size='medium' htmlType='submit' style={{ width: '100%', backgroundColor: '#4500BA', borderColor: '#4500BA', padding: '10px 24px', height: 'auto' }}>Submit Coupon</Button>
                    </Form.Item>
                  </Form>
                </Modal>
                <div className='scan-claim-popup'>
                  {data.expiredAt == null && data.limitCheck === null
                    ? (
                      <Button
                        type='primary'
                        className='unique-btn claim-popup-btn'
                        title='Input Unique Id'
                        onClick={() => setVisibleUniqueId(true)}
                      >
                        Input Unique Id
                      </Button>
                      )
                    : null}
                  <Button
                    type='primary'
                    className='claim-btn claim-popup-btn'
                    title='Claim Coupon'
                    onClick={claimCoupon}
                  >
                    CLAIM COUPON
                  </Button>
                </div>
                {!dataBatch.batchLock && dataBatch.batchSize > 1
                  ? (
                    <div className='scan-claim-popup' style={{ top: 0, bottom: 'auto', paddingTop: '30px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        {[...Array(dataBatch.batchSize)].map((_, i) => {
                          const text = `BATCH ${i + 1} (${dataBatch?.batchCount[i + 1] ?? 0})`
                          return (
                            <Button
                              key={i}
                              type='primary'
                              className='claim-btn claim-popup-btn'
                              style={{ margin: 0 }}
                              title={text}
                              value={i + 1}
                              onClick={handleChangeBatch}
                            >
                              {dataBatch.batch === i + 1
                                ? (
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                    <CaretRightOutlined style={{ color: 'yellow' }} /> {text}
                                  </div>
                                  )
                                : text}
                            </Button>
                          )
                        })}
                      </div>
                    </div>
                    )
                  : null}

              </>
              )
            : data && statusError.includes(data.statusCode) && data.message === 'Qrcode Has Reached Maximum Check'
              ? <span className='text-center px-4'>QR Rusak, produk tidak terjamin. Silahkan tanyakan kepada penjual</span>
              : data && statusError.includes(data.statusCode) && data.message === "Unique Code Doesn't Matched"
                ? <span className='text-center px-4'>Unique Code tidak valid silahkan refresh dan coba lagi!</span>
                : <Spin spinning size='large' className='spin-height' />}
        </Layout>}
    </>
  )
}

const mapStateToProps = ({ reducerScanQR, reducerProductPixel, reducerCoupons, reducerProductGtm }) => {
  return { ...reducerScanQR, ...reducerProductPixel, ...reducerCoupons, ...reducerProductGtm }
}

export const Redirect = connect(mapStateToProps)(_Redirect)

_Redirect.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  data: PropTypes.object,
  dataCoupon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  dataUnique: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  dataBatch: PropTypes.object,
  productPixel: PropTypes.PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  gtmProduct: PropTypes.PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  successClaimCoupon: PropTypes.bool
}
