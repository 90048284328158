/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
import { Dashboard } from 'src/pages/users/Dashboard'
import { Roles } from 'src/pages/roles/Roles'
import { Product } from 'src/pages/products/Product'
import { CreateProduct } from 'src/pages/products/CreateProduct'
import { UpdateProduct } from 'src/pages/products/UpdateProduct'
import { ConfirmProduct } from 'src/pages/products/ConfirmProduct'
import { ActivityLog } from 'src/pages/products/ActivityLog'
import { SubAccount } from 'src/pages/sub-accounts/SubAccount'
import { SettingQR } from 'src/pages/settings-qr/SettingQR'
import { GenerateQR } from 'src/pages/generates-qr/GenerateQR'
import { CreateSubAccount } from 'src/pages/sub-accounts/CreateSubAccount'
import { CreateUser } from 'src/pages/users/CreateUser'
import { UpdateUser } from 'src/pages/users/UpdateUser'
import { MyProfile } from 'src/pages/users/MyProfile'
import { Distro } from 'src/pages/distro/Distro'
import { UpdateDistro } from 'src/pages/distro/UpdateDistro'
import { PreviewProduct } from 'src/pages/products/PreviewProduct'
import { ProductMaster } from 'src/pages/products/ProductMaster'
import { PreviewGenerate } from 'src/pages/generates-qr/PreviewGenerate'
import { EmptyItems } from 'src/pages/common/EmptyItems'
import { ConfirmTopUp } from 'src/pages/topup/confirmTopUp'
import { TopUp } from 'src/pages/topup/TopUp'
import { Traffic } from 'src/pages/traffics/Traffic'
import { FacebookPixel } from 'src/pages/facebook-pixel/FacebookPixel'
import { Coupon } from 'src/pages/coupon/Coupon'
import { CreateCoupon } from 'src/pages/coupon/CreateCoupon'
import { UpdateCoupon } from 'src/pages/coupon/UpdateCoupon'
import { PreviewCoupon } from 'src/pages/coupon/PreviewCoupon'
import { Affiliate } from 'src/pages/affiliates/Affiliate'
import { PreviewAffiliate } from 'src/pages/users/PreviewAffiliate'
import { PreviewWithdraw } from 'src/pages/affiliates/PreviewWithdraw'
import { GuideIndex } from 'src/pages/guides/GuideIndex'
import { GoogleTagManager } from 'src/pages/tag-manager/GoogleTagManager'
import { PrintByOpim } from 'src/pages/print-by-opim/PrintByOpim'
import { PreviewGenerateOpim } from 'src/pages/print-by-opim/PreviewGenerateOpim'
import { Home } from 'src/pages/home/Home'
import { Notification } from 'src/pages/notification/Notification'

export const routesAdmin = (token) => {
  return [
    {
      path: '/dashboard',
      private: true,
      component: <Dashboard token={token} />
    },
    {
      path: '/dashboard/create',
      private: true,
      component: <CreateUser token={token} />
    },
    {
      path: '/dashboard/:id/update',
      private: true,
      component: <UpdateUser token={token} />
    },
    {
      path: '/dashboard/roles',
      private: true,
      component: <Roles token={token} />
    },
    {
      path: '/dashboard/product/:id/update',
      component: <UpdateProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/create',
      component: <CreateProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/list',
      component: <Product token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/:id/detail',
      component: <PreviewProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/confirmation',
      component: <ConfirmProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/topup/confirmation',
      component: <ConfirmTopUp token={token} />,
      private: true
    },
    {
      path: '/dashboard/my-profile',
      private: true,
      component: <MyProfile token={token} />
    },
    {
      path: 'dashboard/affiliate',
      private: true,
      component: <Affiliate token={token} />
    },
    {
      path: 'dashboard/affiliate/:id/detail',
      private: true,
      component: <PreviewWithdraw token={token} />
    },
    {
      path: 'dashboard/print-by-opim',
      private: true,
      component: <PrintByOpim token={token} />
    },
    {
      path: '/dashboard/print-by-opim/:id/detail',
      component: <PreviewGenerateOpim token={token} />,
      private: true
    },
    {
      path: '/dashboard/guides',
      private: true,
      component: <GuideIndex token={token} />
    }
  ]
}

export const routesMaster = (token) => {
  return [
    {
      path: '/dashboard',
      private: true,
      component: <Home token={token} />
    },
    {
      path: '/dashboard/my-profile',
      private: true,
      component: <MyProfile token={token} />
    },
    {
      path: '/dashboard/my-profile/detail',
      private: true,
      component: <PreviewAffiliate token={token} />
    },
    {
      path: '/dashboard/distro',
      private: true,
      component: <Distro token={token} />
    },
    {
      path: '/dashboard/distro/:id/update',
      private: true,
      component: <UpdateDistro token={token} />
    },
    {
      path: '/dashboard/sub-account',
      private: true,
      component: <SubAccount token={token} />
    },
    {
      path: '/dashboard/sub-account/create',
      private: true,
      component: <CreateSubAccount token={token} />
    },
    {
      path: '/dashboard/product',
      component: <ProductMaster token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/:id/update',
      component: <UpdateProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/create',
      component: <CreateProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/:id/update',
      component: <UpdateProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/:id/detail',
      component: <PreviewProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/log',
      component: <ActivityLog token={token} />,
      private: true
    },
    {
      path: '/dashboard/setting-qr',
      component: <SettingQR token={token} />,
      private: true
    },
    {
      path: '/dashboard/generate-qr',
      component: <GenerateQR token={token} />,
      private: true
    },
    {
      path: '/dashboard/generate-qr/:id/detail',
      component: <PreviewGenerate token={token} />,
      private: true
    },
    {
      path: '/dashboard/facebook-pixel',
      component: <FacebookPixel token={token} />,
      private: true
    },
    {
      path: '/dashboard/google-tag-manager',
      component: <GoogleTagManager token={token} />,
      private: true
    },
    {
      path: '/dashboard/analytics',
      component: <Traffic token={token} />,
      private: true
    },
    {
      path: '/dashboard/topup',
      component: <TopUp token={token} />,
      private: true
    },
    {
      path: '/dashboard/coupon',
      component: <Coupon token={token} />,
      private: true
    },
    {
      path: '/dashboard/coupon/create',
      private: true,
      component: <CreateCoupon token={token} />
    },
    {
      path: '/dashboard/coupon/:id/update',
      private: true,
      component: <UpdateCoupon token={token} />
    },
    {
      path: '/dashboard/coupon/:id/detail',
      private: true,
      component: <PreviewCoupon token={token} />
    },
    {
      path: '/dashboard/guides',
      private: true,
      component: <GuideIndex token={token} />
    },
    {
      path: '/dashboard/notification',
      private: true,
      component: <Notification token={token} />
    }
  ]
}

export const routesFreeMaster = (token) => {
  return [
    {
      path: '/dashboard',
      component: <ProductMaster token={token} />,
      private: true
    },
    {
      path: '/dashboard/my-profile',
      private: true,
      component: <MyProfile token={token} />
    },
    {
      path: '/dashboard/my-profile/detail',
      private: true,
      component: <PreviewAffiliate token={token} />
    },
    {
      path: '/dashboard/product/create',
      component: <CreateProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/:id/update',
      component: <UpdateProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/:id/detail',
      component: <PreviewProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/log',
      component: <ActivityLog token={token} />,
      private: true
    },
    {
      path: '/dashboard/setting-qr',
      component: <SettingQR token={token} />,
      private: true
    },
    {
      path: '/dashboard/generate-qr',
      component: <GenerateQR token={token} />,
      private: true
    },
    {
      path: '/dashboard/generate-qr/:id/detail',
      component: <PreviewGenerate token={token} />,
      private: true
    },
    {
      path: '/dashboard/analytics',
      component: <Traffic token={token} />,
      private: true
    },
    {
      path: '/dashboard/facebook-pixel',
      component: <FacebookPixel token={token} />,
      private: true
    },
    {
      path: '/dashboard/topup',
      component: <TopUp token={token} />,
      private: true
    },
    {
      path: '/dashboard/coupon',
      component: <Coupon token={token} />,
      private: true
    },
    {
      path: '/dashboard/coupon/create',
      private: true,
      component: <CreateCoupon token={token} />
    },
    {
      path: '/dashboard/coupon/:id/update',
      private: true,
      component: <UpdateCoupon token={token} />
    },
    {
      path: '/dashboard/coupon/:id/detail',
      private: true,
      component: <PreviewCoupon token={token} />
    },
    {
      path: '/dashboard/notification',
      private: true,
      component: <Notification token={token} />
    },
    {
      path: '/dashboard/guides',
      private: true,
      component: <GuideIndex token={token} />
    }
  ]
}

export const routesSilverMaster = (token) => {
  return [
    {
      path: '/dashboard',
      private: true,
      component: <Distro token={token} />
    },
    {
      path: '/dashboard/my-profile',
      private: true,
      component: <MyProfile token={token} />
    },
    {
      path: '/dashboard/my-profile/detail',
      private: true,
      component: <PreviewAffiliate token={token} />
    },
    {
      path: '/dashboard/:id/update',
      private: true,
      component: <UpdateDistro token={token} />
    },
    {
      path: '/dashboard/product',
      component: <ProductMaster token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/:id/update',
      component: <UpdateProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/create',
      component: <CreateProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/:id/detail',
      component: <PreviewProduct token={token} />,
      private: true
    },
    {
      path: '/dashboard/product/log',
      component: <ActivityLog token={token} />,
      private: true
    },
    {
      path: '/dashboard/setting-qr',
      component: <SettingQR token={token} />,
      private: true
    },
    {
      path: '/dashboard/generate-qr',
      component: <GenerateQR token={token} />,
      private: true
    },
    {
      path: '/dashboard/generate-qr/:id/detail',
      component: <PreviewGenerate token={token} />,
      private: true
    },
    {
      path: '/dashboard/facebook-pixel',
      component: <FacebookPixel token={token} />,
      private: true
    },
    {
      path: '/dashboard/analytics',
      component: <Traffic token={token} />,
      private: true
    },
    {
      path: '/dashboard/topup',
      component: <TopUp token={token} />,
      private: true
    },
    {
      path: '/dashboard/coupon',
      component: <Coupon token={token} />,
      private: true
    },
    {
      path: '/dashboard/coupon/create',
      private: true,
      component: <CreateCoupon token={token} />
    },
    {
      path: '/dashboard/coupon/:id/update',
      private: true,
      component: <UpdateCoupon token={token} />
    },
    {
      path: '/dashboard/coupon/:id/detail',
      private: true,
      component: <PreviewCoupon token={token} />
    },
    {
      path: '/dashboard/notification',
      private: true,
      component: <Notification token={token} />
    },
    {
      path: '/dashboard/guides',
      private: true,
      component: <GuideIndex token={token} />
    }
  ]
}

export const routeSubAccount = (token) => {
  const subaccount = () =>
    localStorage?.getItem('subaccount') && true
      ? JSON.parse(localStorage?.getItem('subaccount'))
      : null

  return [
    {
      path: '/dashboard',
      private: true,
      // component: <ProductMaster token={token} />
      component: <Home token={token} />
    },
    {
      path: '/dashboard/my-profile',
      private: true,
      component: <MyProfile token={token} />
    },
    {
      path: '/dashboard/my-profile/detail',
      private: true,
      component: <PreviewAffiliate token={token} />
    },
    {
      path: '/dashboard/product/create',
      private: true,
      component: <CreateProduct token={token} />
    },
    {
      path: '/dashboard/setting-qr',
      private: true,
      component: !subaccount().accessSettingGenerateQr ? <EmptyItems /> : <SettingQR token={token} />
    },
    {
      path: '/dashboard/generate-qr',
      private: true,
      component: !subaccount().accessGenerateQr ? <EmptyItems /> : <GenerateQR token={token} />
    },
    {
      path: '/dashboard/generate-qr/:id/detail',
      component: <PreviewGenerate token={token} />,
      private: true
    },
    {
      path: '/dashboard/topup',
      component: <TopUp token={token} />,
      private: true
    },
    {
      path: '/dashboard/product',
      component: !subaccount().accessProduct ? <EmptyItems /> : <ProductMaster token={token} />,
      private: true
    },
    {
      path: '/empty',
      component: <EmptyItems token={token} />,
      private: true
    },
    {
      path: '/dashboard/notification',
      private: true,
      component: <Notification token={token} />
    },
    {
      path: '/dashboard/guides',
      private: true,
      component: <GuideIndex token={token} />
    }
  ]
}

export const routeNoAccess = () => {
  return [
    {
      path: '/dashboard',
      private: true,
      component: <EmptyItems />
    }
  ]
}
