export const rules = {
  email: () => {
    return { type: 'email' }
  },
  required: () => {
    return { required: true, message: 'field is required!' }
  },
  date: () => {
    return { type: new Date() }
  },
  any: () => {
    return { type: 'any' }
  },
  min: (num) => {
    return { min: num, message: `input must be more than ${num} characters` }
  },
  max: (num) => {
    return { max: num, message: `input must be less than ${num} characters` }
  },
  numeric: () => {
    return { pattern: /^[0-9]*$/, message: 'input must be a number' }
  },
  maxValue: (num) => {
    return {
      validator: (_, value) => {
        if (!value) return Promise.resolve()
        if (Number(value) > num) return Promise.reject(new Error(`input must be less than ${num}`))
        return Promise.resolve()
      }
    }
  }
}
